<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{ tournament.name }} - {{ $t('teams') }}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <h5 class="text-center">{{ $t('availableMoney') }}: {{ money }}</h5>
    <b-table striped bordered hover :items="teams" :fields="columns">
      <div slot="order" slot-scope="row">
        {{ row.index + 1 }}
      </div>
      <div slot="name" slot-scope="row">
        <router-link :to="{ name: 'TeamBack', params: { lang: lang, console: console, teamId: row.item.id } }">{{ row.value }}</router-link>
      </div>
      <div slot="money" slot-scope="row" class="text-right">
        {{ row.value }}
      </div>
      <div slot="dts" slot-scope="row">
        <router-link v-if="row.item.dt" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: row.item.dt.slug } }">DT {{ row.item.dt.nick }}</router-link>
        <router-link v-else-if="row.item.codt" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: row.item.codt.slug } }">CODT {{ row.item.codt.nick }}</router-link>
      </div>
      <div slot="actions" slot-scope="row" class="text-center">
        <b-button @click="clearPlayers(row.item)" class="mx-2">
          <i class="fa fa-eraser px-1"></i>
        </b-button>
        <b-button @click="openModalMoney(row.item)" class="mx-2">
          <i class="fa fa-usd px-2"></i>
        </b-button>
      </div>
    </b-table>
    <!-- Modal eliminar todos los players del team -->
    <b-modal :title="`${$t('delete')} ${$t('players')}`" headerBgVariant="danger" headerTextVariant="light" id="modalDeletePlayers">
      <h6>{{ $t('deleteAllPlayersOfTeam') }}</h6>
      <h6>{{ team.name }}</h6>
      <template slot="modal-footer">
        <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalDeletePlayers')">{{ $t('not') }}</b-button>
        <b-button type="button" @click="deletePlayers" variant="primary">{{ $t('yes') }}</b-button>
      </template>
    </b-modal>
    <!-- Modal Agregar quitar dinero -->
    <b-form @submit.prevent="addRemoveMoney">
      <b-modal id="modalMoneyTeam" :title="`${team.name} - ${$t('handOutMoney')}`">
        <div class="form-group">
          <label for="option">{{ $t('selectOption') }}</label>
          <select name="option" id="option" class="form-control" v-validate="'required'" :state="validateState('option')" v-model="option">
            <option value="add">{{ $t('add') }}</option>
            <option value="sub">{{ $t('remove') }}</option>
          </select>
          <span class="error-inputs">{{ errors.first('option') }}</span>
        </div>
        <div class="form-group">
          <label for="quantity">{{ $t('quantity') }}</label>
          <b-form-input type="number" v-validate="'required|numeric|min_value:1'" name="quantity" id="quantity" :state="validateState('quantity')" v-model="quantity"/>
          <span class="error-inputs">{{ errors.first('quantity') }}</span>
        </div>
        <template slot="modal-footer">
          <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalMoneyTeam')">{{ $t('not') }}</b-button>
          <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      columns: [
        { key: 'order', label: '#', sortable: false },
        { key: 'name', label: this.$t('team'), sortable: false },
        { key: 'money', label: 'Premium', sortable: false },
        { key: 'dts', label: 'DT/CODT', sortable: false },
        { key: 'actions', label: '', sortable: false }
      ],
      teams: [],
      team: {},
      option: 'add',
      quantity: 0,
      money: 0
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'tournament',
      'console'
    ])
  },
  mounted() {
    if (!this.tournament.name) {
      this.$router.push({ name: 'Tournaments', params: { lang: this.lang, console: this.console } })
      return
    }
    this.changeTitleBar(this.tournament.name + ' - ' + this.$t('teams'))
    this.fetchData()
  },
  methods: {
    openModalMoney (team) {
      this.team = team
      this.option = 'add'
      this.quantity = 0
      this.$root.$emit('bv::show::modal', 'modalMoneyTeam')
    },
    addRemoveMoney () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = {
            team_id: this.team.id,
            option: this.option,
            quantity: this.quantity
          }
          const tournamentId = this.$route.params.tournamentId
          const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/teams/add-remove-money`
          this.$axios.post(path, payload).then(() => {
            this.$toastr.success(this.$t('updatedInfo'))
            this.teams = this.teams.map(t => {
              if (t.id === this.team.id) {
                if (this.option === 'add') {
                  t.money = parseInt(t.money) + parseInt(this.quantity)
                } else {
                  t.money = parseInt(t.money) - parseInt(this.quantity)
                }
              }
              return t
            })
            const updateTournamentMoney = {
              tournament_id: this.tournament.id,
              option: this.option === 'add' ? 'sub' : 'add',
              quantity: this.quantity
            }
            this.$store.commit('SET_TOURNAMENT_MONEY', updateTournamentMoney)
            if (updateTournamentMoney.option === 'add') {
              this.money = parseInt(this.money) + parseInt(updateTournamentMoney.quantity)
            } else {
              this.money = parseInt(this.money) - parseInt(updateTournamentMoney.quantity)
            }
            this.$root.$emit('bv::hide::modal', 'modalMoneyTeam')
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    deletePlayers () {
      const payload = {
        team_id: this.team.id
      }
      const tournamentId = this.$route.params.tournamentId
      const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/teams/delete-all-players`
      this.$axios.post(path, payload).then(() => {
        this.$toastr.success(this.$t('updatedInfo'))
        this.$root.$emit('bv::hide::modal', 'modalDeletePlayers')
      })
    },
    clearPlayers (team) {
      this.team = team
      this.$root.$emit('bv::show::modal', 'modalDeletePlayers')
    },
    fetchData () {
      const tournamentId = this.$route.params.tournamentId
      const params = {
        page: this.currentPage
      }
      const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/teams`
      this.$axios.get(path, { params }).then((response) => {
        this.teams = response.data.teams
        this.money = response.data.money
      })
    }
  }
}
</script>
